
// JQUERY PLUGINS
require("./application/velocity.min")
require("./application/velocity.ui")
require("./jquery/browser")
require("./jquery/autocomplete")
require("./jquery/color")
require("./jquery/cookie")
require("./jquery/dimensions")
require("./jquery/plugins")
require("./jquery/livequery")
require("./jquery/form")
require("./jquery/example")
require("./jquery/blockUI")
require("./jquery/linebox")
require("./jquery/facebox")
require("./jquery/faceboxtweaks")
require("./jquery/facetip")
require("./jquery/embedly")
require("./jquery/rating")
require("./jquery/elastic")
require("./jquery/freewall")
require("./jquery/jquery.sortable")
require("./jquery/jquery.dotdotdot.min")
require("./jquery/jquery.quick.pagination")
require("./jquery/imagesloaded.pkgd.min")
require("./jquery/textareaExpand")
require("./jquery/jquery.tablesorter.min")
require("./jquery/jquery.tablesorter.pager")
require("./jquery/Chart.min")
require("./jquery/picker")
require("./jquery/picker.date")
require("./jquery/picker.time")
require("./jquery/cookieconsent")
require("./jquery/scroll-nav")
require("./jquery/readmore")
require("./jquery/stick-to-top")
require("./jquery/full-screen-popup")
require("./jquery/localizedGrade")

// APPLICATION JAVASCRIPTS
require("./application/helpers")
require("./application/localstorage")
require("./application/toponav")
require("./application/preferences")
require("./application/messages")
require("./application/add_many")
require("./application/grades")
require("./application/print_page")
require("./application/gradechart")
require("./application/tabbar")
require("./application/csv_import")

require("./application/application")